<template> 
    <div class="mt-4">
            <div class="w-full md:w-1/2 flex flex-row">
                <div class="w-1/5 ">
                    <h6> {{ this.$t('resorts.touristic-objects.form.selection') }} </h6>

                </div>
                
            </div>
            <div>
                <div v-if="selections && selections.length > 0"  class="flex items-center w-full md:w-2/5 mt-4 ">  
                    <label  class="text-sm w-1/2 ">{{ $t('resorts.touristic-objects.form.selectionType') }} </label>
                    <label  class="text-sm w-1/2 pl-5">{{ $t('resorts.touristic-objects.form.weightInput') }} </label>
                </div>
            </div>

            <div v-for="(option,index) in this.selections" :key="index">
                <div  class="flex items-center mt-1">
                    <InputCharCounter                         
                        componentClases="w-1/2 md:w-1/5 h-16"
                        :ModelValue="option.selection"
                        :maxLength=100
                        @input = "(value) => { option.selection = value;  }"
                        :disabled="disabled"
                        :isCounterInvisible="isCounterInvisible"
                    ></InputCharCounter>
                    
                    <div class="w-1/2 md:w-5/12 flex flex-row items-center  ">                        
                            <vs-input class="pb-5 ml-5 w-2/3 md:w-3/12 "                             
                                type="number"
                                :value="option.weight || 0 "  
                                v-model="option.weight"
                                :disabled="disabled"
                            />                        
                        <div class="ml-3" >
                            <vs-button v-if="!disabled" type="border" color="rgb(255, 0, 0)"  @click="deleteRow(index)"  class="px-2 pt-1 pb-0 mb-5 mr-2 rounded-lg " ><feather-icon icon="TrashIcon" svgClasses=" align-middle w-6"/></vs-button>
                        </div>

                    </div>              
                </div>    
                         
            </div>
            <div class="w-2/3 mt-5">
                <vs-button v-if="!disabled"
                    type="border"
                    class="rounded-lg xs:w-full sm:w-auto text-primary"
                    @click="add" >{{ this.$t('resorts.touristic-objects.form.addSelection') }} </vs-button>
            </div> 
    </div>                 
</template>

<script>
    import InputCharCounter from "@/modules/Shared/Components/form/InputCharCounter.vue";

    export default {
        name: 'selectionExpandable',
        components: {
            InputCharCounter,
        },
        props: {
            selections:{type: Array,required: false}, 
            disabled: { type: Boolean, default: false, required: false },
            isCounterInvisible: { type: Boolean, required: false, default: false },
            isViewMode:{type: Boolean, required: false, default: false }
        },       
        methods: { 
            add(){
               this.selections.push( {'selection':"",'weight':9999})
            },
            deleteRow(index) {
                this.selections.splice(index, 1)
            }
        },        
    }
</script>
