<template>
    <div class="mt-4 bg-white p-8">

         <h4 >{{ this.$t('resorts.touristic-objects.form.mainTitle') }}</h4>
         <p class = "text-sm text-dark mb-8">{{ this.$t('resorts.touristic-objects.form.mainSubtitle') }}</p>

        <div v-if="isAdmin" class="flex items-center ml-0 ">
            <vs-input class="border-grey-light xs:w-full md:w-1/3 lg:w-2/12 pb-2"
                type="text"
                :value="touristicObject.resort_reference || '' "
                v-model="touristicObject.resort_reference"
                :label="this.$t('resorts.touristic-objects.form.resortReference')"
                required
            />
        </div>

         <vs-checkbox
            class="ml-0 mb-4 mt-4 text-md font-bold text-dark"
            v-model="touristicObject.published"
            >{{ this.$t('resorts.touristic-objects.form.published') }}
        </vs-checkbox>
        <div v-if="pageType == 'events'"  class="flex items-center ml-0 gap-4 mt-4">
            <b-form-datepicker  class="w-80 mb-1 rounded-lg"
                today-button
                reset-button
                close-button
                v-model="touristicObject.start_date"
                :placeholder="this.$t('resorts.touristic-objects.form.startDate')"
                name="end"
                required
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
            <b-form-datepicker  class="w-80 mb-1 rounded-lg"
                today-button
                reset-button
                close-button
                v-model="touristicObject.end_date"
                :min="touristicObject.start_date"
                :placeholder="this.$t('resorts.touristic-objects.form.endDate')"
                name="end"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
        </div>
        <div class="flex items-center ml-0 gap-4">
            <div class="w-full md:w-1/3 lg:w-2/12">

                <vs-select class="border-grey-light w-full pb-2" v-model="touristicObject.timeofyear" required>
                    <vs-select-item :key="option.key" :value="option.key" :text="option.label | capitalize" v-for="option in this.timeofyearItems"/>
                </vs-select>


            </div>
        </div>


        <selectionExpandable
            :selections="touristicObject.selections"
        ></selectionExpandable>


        <h6 class="mt-8" > {{ this.$t('resorts.touristic-objects.form.contentLanguage') }}</h6>
        <div class=" items-center ml-0 gap-4">
                <div class="w-full md:w-1/3 lg:w-2/12 mt-2">
                    <label  class="text-sm w-full">{{ $t('resorts.touristic-objects.form.mainLanguagesInput') }} </label>
                    <vs-select class="border-grey-light w-full pb-3" v-model="defaultLanguage" required  :placeholder="this.$t('resorts.touristic-objects.form.defaultLanguage')" >
                        <vs-select-item :key="option.id" :value="option"  :text="option.name" v-for="option in this.langs"/>
                    </vs-select>
                </div>
                <div class="w-full md:w-1/3 lg:w-2/12 ">
                    <label  class="text-sm w-full ">{{ $t('resorts.touristic-objects.form.languagesInput') }} </label>
                    <v-select class="border-grey-light w-full v-selector"  multiple  :placeholder="this.$t('resorts.touristic-objects.form.languages')"
                        v-model="selectedLanguages"
                        :options="langs"
                        label="name"
                        :selectable="(option) => defaultLanguage !== null && option.id != defaultLanguage.id"
                    />
                </div>
        </div>

        <div class="mb-8 mt-6 w-full">
            <h6 v-if="zones != null || this.$route.meta.type == 'services' " class="mb-5"> {{ this.$t('resorts.touristic-objects.form.segmentation') }}</h6>

            <label v-if="pageType !== 'events'" class="text-sm w-full md:w-1/2 ">{{ this.$t('resorts.touristic-objects.form.typeInput') }}</label>
            <vs-select v-if="pageType !== 'events'"  class="border-grey-light w-full md:w-1/3 lg:w-2/12" v-model="touristicObjectType" :placeholder="this.$t('resorts.touristic-objects.form.type')" @change="changeType">
                <vs-select-item :key="option.id" :value="option.id" :text="option.name" v-for="option in types"/>
            </vs-select>

            <label v-if="zones != null"  class="text-sm w-1/2 mt-2 ">{{ this.$t('resorts.touristic-objects.form.zoneInput') }}</label>
            <vs-select v-if="zones != null" required class="border-grey-light w-full md:w-1/3 lg:w-2/12" v-model="touristicObjectZone"  :placeholder="this.$t('resorts.touristic-objects.form.zone')"  >
                <vs-select-item :key="option.uuid" :value="option.uuid" :text="option.name" v-for="option in zones"/>
            </vs-select>
        </div>


        <div class="mt-10" v-if="showTitle == true">
            <h4 v-if = "pageType =='services'" class="mt-6"  >{{ this.$t('resorts.touristic-objects.form.descService') }} </h4>
            <h4 v-if = "pageType =='events'" class="mt-6"  >{{ this.$t('resorts.touristic-objects.form.descEvent') }} </h4>
            <p  v-if = "pageType =='services'" class = "text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.descServiceSub') }}</p>
            <p v-if = "pageType =='events'" class = "text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.descEventSub') }}</p>
            <h4 v-if = "pageType =='facilities'" class="mt-6"  >{{ this.$t('resorts.touristic-objects.form.descFacility') }} </h4>
            <p  v-if = "pageType =='facilities'" class = "text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.descFacilitySub') }}</p>
        </div>

        <div v-if="selectedLanguages" class="flex flex-row flex-wrap mb-10">
            <TouristicObjectFormCard
                v-for="(language) in selectedLanguages.filter(l => l.id != defaultLanguage.id)"
                :key="language.id"
                :language="language"
                :selectedLanguages="selectedLanguages"
                :deletedTranslations="deletedTranslations"
                :defaultLanguage="defaultLanguage"
                :disabled="false"
                :editMode="true"
            />
            <TouristicObjectFormCard
                v-if="defaultLanguage"
                :key="defaultLanguage.id"
                :language="defaultLanguage"
                :selectedLanguages="selectedLanguages"
                :defaultLanguage="defaultLanguage"
                :disabled="false"
                :editMode="true"
            />



            <div class="flex flex-row flex-wrap mt-4 w-full">

                <div class="flex flex-row flex-wrap w-full md:w-2/3">
                    <vs-input class="border-grey-light pb-2 md:pr-2 w-full md:w-1/2"
                        type="number"
                        min="0"
                        step="0.01"
                        :value="touristicObject.min_height"
                        v-model="touristicObject.min_height"
                        :label="this.$t('resorts.touristic-objects.form.min_height') + ` (${this.$options.filters.unit_system_converter('depth', this.referenceSystem)})`"
                    />
                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                              type="number"
                              min="0"
                              step="0.01"
                              :value="touristicObject.max_height"
                              v-model="touristicObject.max_height"
                              :label="this.$t('resorts.touristic-objects.form.max_height') + ` (${this.$options.filters.unit_system_converter('depth', this.referenceSystem)})`"
                    />
                </div>

                <div class="flex flex-row flex-wrap w-full md:w-2/3">
                    <vs-input class="border-grey-light pb-2 md:pr-2 w-full md:w-1/2"
                              type="number"
                              min="0"
                              step="0.01"
                              :value="touristicObject.start_height"
                              v-model="touristicObject.start_height"
                              :label="this.$t('resorts.touristic-objects.form.start_height') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                    />
                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                              type="number"
                              min="0"
                              step="0.01"
                              :value="touristicObject.end_height"
                              v-model="touristicObject.end_height"
                              :label="this.$t('resorts.touristic-objects.form.end_height') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                    />
                </div>

                <div class="flex flex-row flex-wrap w-full md:w-2/3">
                    <vs-input class="border-grey-light pb-2 md:pr-2 w-full md:w-1/2"
                              type="number"
                              min="0"
                              step="0.01"
                              :value="touristicObject.total_ascent"
                              v-model="touristicObject.total_ascent"
                              :label="this.$t('resorts.touristic-objects.form.total_ascent') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                    />
                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                              type="number"
                              min="0"
                              step="0.01"
                              :value="touristicObject.total_descent"
                              v-model="touristicObject.total_descent"
                              :label="this.$t('resorts.touristic-objects.form.total_descent') + ` (${this.$options.filters.unit_system_converter('height', this.referenceSystem)})`"
                    />
                </div>

                <div class="w-full md:w-2/3">
                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                            type="number"
                            min="0"
                            step="0.01"
                            :value="touristicObject.length"
                            v-model="touristicObject.length"
                            :label="this.$t('resorts.touristic-objects.form.length') + ` (${this.$options.filters.unit_system_converter('distance', this.referenceSystem)})`"
                    />

                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                            type="number"
                            min="0"
                            :value="touristicObject.time"
                            v-model="touristicObject.time"
                            :label="this.$t('resorts.touristic-objects.form.time')"
                    />

                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                            type="number"
                            min="0"
                            :value="touristicObject.min_age"
                            v-model="touristicObject.min_age"
                            :label="this.$t('resorts.touristic-objects.form.min_age')"
                    />

                    <vs-input class="border-grey-light pb-2 w-full md:w-1/2"
                            type="number"
                            min="0"
                            :value="touristicObject.tickets"
                            v-model="touristicObject.tickets"
                            :label="this.$t('resorts.touristic-objects.form.tickets')"
                    />

                    <vs-select :label="this.$t('resorts.touristic-objects.form.page')" class="border-grey-light pb-2 w-full md:w-1/2" v-model="touristicObject.page_id" required>
                        <vs-select-item :value="null" text="-"/>
                        <vs-select-item :key="option.id" :value="option.id" :text="option.reference" v-for="option in this.pages"/>
                    </vs-select>

                    <vs-select :label="this.$t('resorts.touristic-objects.form.subtype')" class="border-grey-light pb-2 w-full md:w-1/2" v-model="subtypeId" required>
                        <vs-select-item :value="null" text="-"/>
                        <vs-select-item :key="option.id" :value="option.id" :text="option.name" v-for="option in this.subtypes"/>
                    </vs-select>

                    <vs-select :label="this.$t('resorts.touristic-objects.form.ride_thrill')" class="border-grey-light pb-2 w-full md:w-1/2" v-model="rideThrillId">
                        <vs-select-item :value="null" text="-"/>
                        <vs-select-item :key="option.id" :value="option.id" :text="option.key" v-for="option in this.rideThrills"/>
                    </vs-select>

                    <vs-select :label="this.$t('resorts.touristic-objects.form.ride_audience')" class="border-grey-light pb-2 w-full md:w-1/2" v-model="rideAudienceId">
                        <vs-select-item :value="null" text="-"/>
                        <vs-select-item :key="option.id" :value="option.id" :text="option.key" v-for="option in this.rideAudiences"/>
                    </vs-select>

                    <div class="w-full pb-2">
                        <label  class="text-sm w-full pl-1">{{ $t('resorts.touristic-objects.form.requirements') }} </label>
                        <v-select class="border-grey-light v-selector"  multiple  :placeholder="this.$t('resorts.touristic-objects.form.requirements')"
                                v-model="requirementsSelected"
                                :options="requirements"
                                label="key"
                            />

                    </div>

                    <div class="w-full">
                        <label  class="text-sm w-full pl-1">{{ this.$t("facilities.tableheader.difficulty") }} </label>
                        <v-select
                            class="bg-white search-select rounded-lg w-full md:w-1/2"
                            @input="handleSelectDifficultyChange"
                            :options="getFacilityDifficultySelect()"
                            :value="difficultyId"
                            :appendToBody="true"
                        >
                            <template #option="option">
                                <span v-if="option.icon" class="w-8 inline-block text-center">
                                    <img class="w-6" :src="option.icon" />
                                </span>
                                {{ option.label }}
                            </template>
                            <template #selected-option="option">
                                <span v-if="option.icon" class="w-8">
                                    <img class="w-6" :src="option.icon" />
                                </span>
                                {{ option.label }}
                            </template>
                        </v-select>
                    </div>

                </div>
            </div>
        </div>

        <h4 >{{ this.$t('resorts.touristic-objects.form.addImage') }}</h4>
        <p class="text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.addImageSub') }}</p>

            <ImagesForm
                :images="images"
                :deletedImages = "deletedImages"
                :allowedExtensions="imageAllowedExtensions"
                id="selectToImage"
                :validationWidth="1080"
                :validationHeight="575"
                :validationWeight="5500"
                :validationMessage="validationMessageImage"
                width="240"
                height="170"
                name="selectedImages"
                @maximize="modalPhoto"
                @imagesDeleted="deleteAllImages"
            ></ImagesForm>

            <p class="text-xs text-grey mt-2">  {{ this.$t('resorts.touristic-objects.form.imageSpecification') }}</p>
            <vs-popup  class="modalMap" v-model="isModalOpen" :active.sync="isModalOpen" title="">
                <img :src="maximizedImage.url" :style="{maxWidth:  '100%', maxHeight: '100%' }">
            </vs-popup>

        <vs-checkbox v-model="hasCoordinates" class="my-10 checkboxform border-0 mx-0">
            {{ this.$t('resorts.touristic-objects.form.need_coords') }}
        </vs-checkbox>

        <div v-if="hasCoordinates">
            <h4>{{ this.$t('resorts.touristic-objects.form.locateMap') }}</h4>
            <p class="text-sm text-dark">{{ this.$t('resorts.touristic-objects.form.locateMapSub') }}</p>
            <MapCoordinates
                :defaultCoordinates="defaultCoordinates"
                :componentSize="mapSize"
                @updated="handleMapChange"
                @invalidCoords="handleInvalidCoords"
                :zoom=18
            ></MapCoordinates>
        </div>

        <mapCoordinatesExpandable
            :map-coordinates="mapCoordinates"
        />

        <div class="flex items-center ml-0 gap-4 mt-5">
            <vs-button
               class="rounded-lg xs:w-full sm:w-auto ml-2 "
              @click="save" >
              {{ this.$t('resorts.touristic-objects.form.saveButton') }}
            </vs-button>
            <vs-button
                type="border"
               class="rounded-lg xs:w-full sm:w-auto text-primary"
              @click="cancel" >
              {{ this.$t('resorts.touristic-objects.form.cancelButton') }}
            </vs-button>
        </div>
    </div>
</template>
<script>
import TouristicObjectFormCard from '@/modules/Resorts/Pages/touristicObjects/forms/TouristicObjectFormCard';
import TouristicObjectServices from '@/modules/Resorts/Services/TouristicObjectServices'
import PagesService from '@/modules/Resorts/Services/PagesService'
import ImagesForm from '@/modules/Resorts/Pages/touristicObjects/forms/ImagesForm'
import MapCoordinates from '@/modules/Shared/Components/mapcoordinates/MapCoordinates.vue';
import loader from "@/modules/Shared/Mixins/loader";
import selectionExpandable from '@/modules/Resorts/Pages/touristicObjects/components/selectionExpandable'
import mapCoordinatesExpandable from '@/modules/Resorts/Pages/touristicObjects/components/mapCoordinatesExpandable'
import {mapGetters} from "vuex";
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import { getFacilityDifficulty } from "@/modules/Resorts/Helpers/facilityHelper"
import store from "@/modules/Shared/Store/store";
import {userHasAuthorization} from "@/modules/Auth/Helpers/drmHelper";

export default {
    name: 'edit-touristicObject',
    mixins: [loader],
    components: {
        TouristicObjectFormCard,
        ImagesForm,
        MapCoordinates,
        selectionExpandable,
        mapCoordinatesExpandable
    },
    props: {
        create: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
        }
    },
    data() {
        return {
            referenceSystem: 'metric',
            difficultyStyle: 'metric',
            actionType:'',
            pageType:'',
            updatedData:[],
            touristicObject: {},
            touristicObjectType:{},
            touristicObjectZone:{},
            updateParams:[],
            types:[],
            langs:[],
            zones:[],
            defaultLanguage:null,
            selectedLanguages: [],
            deletedTranslations:[],
            images:[],
            deletedImages:[],
            timeofyearItems : [ 
                { key: 'winter', label: this.$t('winter') }, 
                { key: 'summer', label: this.$t('summer') }, 
                { key: 'always', label: this.$t('always') }, 
            ],
            validationMessageImage: this.$t("banner.form.imageerror"),
            imageAllowedExtensions:["image/jpg","image/jpeg"],
            isModalOpen: false,
            maximizedImage:{},
            hasCoordinates: false,
            defaultCoordinates : {lat: 0, lon: 0},
            mapSize : {width:"w-2/3 md:w-3/3 lg:w-2/2"},
            showTitle: false ,
            maxInputLength100: 100,
            mapCoordinates: [],
            subtypeId: null,
            rideThrillId: null,
            rideAudienceId: null,
            difficultyId: null,
            requirementsSelected: [],
            pages: [],
            rideThrills: [],
            rideAudiences: [],
            requirements: [],
            subtypes: [],
      }
    },
    async beforeRouteEnter (to, from, next) {
        const touristicObject = to.params.create
            ? null
            : (await TouristicObjectServices.getTouristicObject(to.params.touristicObjectUuid)).data.object
        await store.dispatch('setRouteElement', touristicObject)
        next(vm => {
            vm.touristicObject = touristicObject
                ? touristicObject
                : {
                    uuid : to.params.touristicObjectUuid,
                    internal_identifier: to.params.touristicObjectUuid,
                    name : "",
                    category :"",
                    type:"",
                    zone: "",
                    default_language : {},
                    selection:"",
                    published:false,
                    address  :"",
                    coordinates: {
                        lat:0,
                        lon:0,
                    },
                    start_date:"",
                    end_date :"",
                    priority :"",
                    weight   : 9999,
                    timeofyear:'Winter',
                    image_list:[],
                    langs_list:[],
                    selections: [],
                    resort_reference : "",
                    min_height: null,
                    max_height: null,
                    min_age: null,
                    tickets: null,
                    length: null,
                    start_height: null,
                    end_height: null,
                    time: null,
                    page_id: null,
                    total_ascent: null,
                    total_descent: null,
                    map_coordinates: [[null, null]],
                    heights: [null],
                    ride_thrill: null,
                    ride_audience: null,
                    subtype: null,
                    requirements: [],
                }
        } )
    },
    computed: {
        ...mapGetters('resorts', ['resorts']),
      selectionLength() {return  this.touristicObject.selection ? (this.touristicObject.selection).toString().length : 0},
      isAdmin() { 
        return userHasAuthorization([{
            context: 'admin',
            drm: 'apps'
        }])
    }
    },
    async created() {
        await this.load(async () => {
            this.pages = (await PagesService.getPages(this.$route.params.uuid)).data
            this.referenceSystem = this.resorts.find(resort => resort.uuid === this.$route.params.uuid).reference_system
            this.difficultyStyle = this.resorts.find(resort => resort.uuid === this.$route.params.uuid).slope_difficulty_referencesystem
            this.pageType = this.$route.meta.type

            const types = await TouristicObjectServices.getTouristicObjectTypes()
            const languages= await TouristicObjectServices.getLangs()
            const zones = await TouristicObjectServices.getTouristicObjecZones(this.$route.params.uuid)
            this.rideThrills = await unifyPaginatedResponse(TouristicObjectServices.getRideThrills, { q: `resort_uuid:${this.$route.params.uuid}`, per_page:30 })
            this.rideAudiences = await unifyPaginatedResponse(TouristicObjectServices.getRideAudiences, { q: `resort_uuid:${this.$route.params.uuid}`, per_page:30 })
            this.requirements = await unifyPaginatedResponse(TouristicObjectServices.getRequirements, { q: `resort_uuid:${this.$route.params.uuid}`, per_page:30 })

            this.zones = zones.data.object.length > 0 ?  zones.data.object : null
            this.langs = languages.data.object
            this.types = types.data.object

            if(this.create){
                this.actionType='create'
            } else {
                this.touristicObjectType = this.touristicObject.type.id
                this.touristicObjectZone = this.touristicObject.zone != null ? this.touristicObject.zone.uuid:""
                this.actionType ='update'
            }
            this.handleToForm()
        })
    },
    watch: {
        images(newValue){
            this.sortImagesByMain(newValue)
        },
        touristicObjectZone(newValue){
            this.touristicObject.zone = newValue
        },
        touristicObjectType(newValue){
            this.touristicObject.type = newValue
        },
        selectedType(newItem) {
            if (newItem) {
                this.touristicObject.type.id = newItem.id;
                this.touristicObject.type.name = newItem.name;
            }
        },
        defaultLanguage(newDefaultlanguage,oldDefaultLanguage){
            this.showTitle = true;

            if(!oldDefaultLanguage || Object.keys(oldDefaultLanguage).length === 0){
                this.selectedLanguages.push(newDefaultlanguage)
                this.touristicObject.default_language = {
                    'id':newDefaultlanguage.id,
                    'code' : newDefaultlanguage.default_locale
                }
            }else{
                let exist = this.selectedLanguages.filter(language => language.id == newDefaultlanguage.id)

                if (Object.keys(exist).length === 0){
                    this.selectedLanguages.push(newDefaultlanguage)
                }
                this.touristicObject.default_language = {
                    'id':newDefaultlanguage.id,
                    'code' : newDefaultlanguage.default_locale
                }

            }

        },
        selectedLanguages(language, oldSelectedLanguages) {
            oldSelectedLanguages
                .filter(oldSelectedLanguage => !language.includes(oldSelectedLanguage) && oldSelectedLanguage.translation.idioma_id != this.touristicObject.default_language.id)
                .forEach(oldSelectedLanguage => this.deletedTranslations.push(oldSelectedLanguage.translation))
            language.map((item)=>{
                if(!item.touristicObject ){
                    item['touristicObject'] = this.touristicObject
                    let traduccion = this.touristicObject.langs_list.filter(translation => translation.idioma_id == item.id)
                        if(traduccion.length == 1){
                            item['translation'] = traduccion[0]
                            item['translation']['new'] = false
                        }else{
                            item['translation'] = {
                                new:true,
                                name : this.touristicObject.name,
                                idioma_id : item.id,
                                phone :"",
                                other_phone: "",
                                email: "",
                                other_email: "",
                                web: "",
                                reservation_link: "",
                                rates:  "",
                                schedule:  "",
                                services_tags: [],
                                equipments_tags: [],
                                comfort_tags: [],
                                accessibility_tags: [],
                                payments_mode_tags: [],
                            }
                        }
                }
            })
        },
    },
    methods: {
        getFacilityDifficultySelect() {
            return getFacilityDifficulty(this.difficultyStyle)
        },

        async changeType(newType) {
            this.subtypes = await unifyPaginatedResponse(TouristicObjectServices.getTouristicObjectSubTypes, {
                q: `type_id:${newType}`,
                per_page: 30
            })
        },
        getCategory() {
            switch (this.pageType) {
                case 'services':
                    return 'service'
                case 'events':
                    return 'event'
                case 'facilities':
                    return 'facility'
            }
        },
        parseMapCoordinatesAndHeightToForm(mapCoordinates, heights) {
            if (!mapCoordinates || !heights) {
                this.mapCoordinates = [
                    { lat: null, lng: null, height: null }
                ]
                return
            }

            for (let i = 0; i < mapCoordinates.length; i++) {
                this.mapCoordinates.push({
                    lat: mapCoordinates[i][0],
                    lng: mapCoordinates[i][1],
                    height: heights[i]
                })
            }
        },
        parsePointsToMapCoordinatesAndHeights() {
            let map_coordinates = []
            let heights = []

            this.mapCoordinates
                .filter(mapCoordinate => mapCoordinate.lat !== null && mapCoordinate.lng !== null && mapCoordinate.height !== null)
                .forEach(mapCoordinate => {
                    map_coordinates.push([Number(mapCoordinate.lat), Number(mapCoordinate.lng)])
                    heights.push(Number(mapCoordinate.height))
            })

            return { map_coordinates, heights }
        },
        deleteAllImages(){
            this.images.forEach(image => {
                this.deletedImages.push(image)
            });
          this.images=[]
        },
        handleToForm(){
            this.langs.map((item) =>{
                if(item.id == this.touristicObject.default_language.id){
                    this.defaultLanguage = item
                }
            })
            this.hasCoordinates = this.touristicObject.coordinates !== null;
            if (this.hasCoordinates) {
                this.defaultCoordinates = { lat: this.touristicObject.coordinates.lat, lon: this.touristicObject.coordinates.lon }
            }
            this.images = this.sortImagesByMain(this.touristicObject.image_list)
            this.touristicObject.category = this.$route.meta.type == 'services' ? 'service' : 'event'

            this.touristicObject.langs_list.map((item)=>{
                if (item.idioma_id != this.touristicObject.default_language.id){
                    this.langs.forEach(lang => {
                        if(item.idioma_id == lang.id){
                            this.selectedLanguages.push(lang)
                        }
                    });
                }
            })

            this.subtypeId = this.touristicObject.subtype ? this.touristicObject.subtype.id : null
            this.rideThrillId = this.touristicObject.ride_thrill ? this.touristicObject.ride_thrill.id : null
            this.difficultyId = this.touristicObject.facility_difficulty ? this.getFacilityDifficultySelect().find((item) => item.value === this.touristicObject.facility_difficulty.name ) : null
            this.rideAudienceId = this.touristicObject.ride_audience ? this.touristicObject.ride_audience.id : null
            this.requirementsSelected = this.touristicObject.requirements ? this.touristicObject.requirements : []

            this.parseMapCoordinatesAndHeightToForm(this.touristicObject.map_coordinates, this.touristicObject.heights)

            if (this.referenceSystem === 'imperial') {
                this.touristicObject.min_height = this.$options.filters.value_system_converter(this.touristicObject.min_height, this.referenceSystem, 'depth')
                this.touristicObject.max_height = this.$options.filters.value_system_converter(this.touristicObject.max_height, this.referenceSystem, 'depth')
                this.touristicObject.start_height = this.$options.filters.value_system_converter(this.touristicObject.start_height, this.referenceSystem, 'height')
                this.touristicObject.end_height = this.$options.filters.value_system_converter(this.touristicObject.end_height, this.referenceSystem, 'height')
                this.touristicObject.total_ascent = this.$options.filters.value_system_converter(this.touristicObject.total_ascent, this.referenceSystem, 'height')
                this.touristicObject.total_descent = this.$options.filters.value_system_converter(this.touristicObject.total_descent, this.referenceSystem, 'height')
                this.touristicObject.length = this.$options.filters.value_system_converter(this.touristicObject.length, this.referenceSystem, 'distance')
            }

        },
        modalPhoto(image){
            this.maximizedImage = image
            this.isModalOpen = true
        } ,
        handleMapChange(eventDataNewCoordinates){
            this.touristicObject.coordinates = {
                lat: eventDataNewCoordinates.lat,
                lon: eventDataNewCoordinates.lon
            }
        },
        sortImagesByMain(images){
            return images.slice().sort((a, b) => {
                if (a.main && !b.main) {
                    return -1;
                } else if (!a.main && b.main) {
                    return 1;
                } else {
                    return 0;
                }
            })
        },
        cancel(){
            window.top.postMessage('touristic-object-canceled', '*')
            this.goBackToList()
        },
        save(){
            if(this.actionType === 'create'){
                this.createTouristicObject()
            }else{
                this.updateTouristicObject()
            }
        },
        async createTouristicObject(){
            let touristicObject = this.generateTouristicObjectPayload();
            let response = await TouristicObjectServices.createTouristicObject( this.touristicObject.uuid, touristicObject)
            if(response.status == 200 && Object.keys(response.data).length === 0){
                this.handleTranslations()
                this.handleImages()
                window.top.postMessage('touristic-object-created', '*')
                this.goBackToList()
            }
        },

        async updateTouristicObject(){
            let touristicObject = this.generateTouristicObjectPayload();
            if(touristicObject != undefined){
                let response = await TouristicObjectServices.updateTouristicObject( this.touristicObject.uuid, touristicObject)
                if(response.data.code == 200){
                   this.handleTranslations()
                   this.handleImages()
                    window.top.postMessage('touristic-object-updated', '*')
                }
                this.goBackToList()
            }
        },
        handleTranslations(){
            this.selectedLanguages.forEach(async (touristicObjectPack) => {
                let translation = touristicObjectPack.translation

                if(translation.new){
                    await TouristicObjectServices.createTouristicObjectTranslation( this.touristicObject.uuid,this.generateNewTouristicObjectTranslationPayload(translation))
                }else {
                   await TouristicObjectServices.updateTouristicObjectTranslation( this.touristicObject.uuid,this.generateTouristicObjectTranslationPayload(translation))
                }
            })
            this.deletedTranslations.forEach(async (deletedTranslation) =>{
                await TouristicObjectServices.deleteTouristicObjectTranslation( this.touristicObject.uuid,deletedTranslation.idioma_id)
            })
        },
        handleImages(){
            this.images.forEach(async (image) =>{
                if(image.new){
                    await TouristicObjectServices.createTouristicObjectImage( this.touristicObject.uuid,image)
                }else{
                    await TouristicObjectServices.updateTouristicObjectImage( this.touristicObject.uuid,image)
                }
            })
            if(this.deletedImages.length > 0) {
                this.deletedImages.forEach(async (deletedImage) =>{
                    await TouristicObjectServices.deleteTouristicObjectImage( this.touristicObject.uuid,deletedImage.uuid)
                })
            }
        },

        generateTouristicObjectPayload(){
            let errors = []

            if(this.$route.meta.type == 'events'){
                errors = this.validateEvent(this.touristicObject)

            }else{
                errors = this.validateService(this.touristicObject)
            }
            if(errors.length > 0){
                this.$vs.notify({
                    type:'confirm',
                    title: "Error",
                    text: `${errors[0]}`,
                    color: "danger",
                    position: "top-right"
                });
            }else{
                if(this.touristicObject.address == null) this.touristicObject.address = ''
                let defaultLanguage = this.selectedLanguages.filter(lang => lang.id === this.touristicObject.default_language.id)[0]

                const {map_coordinates, heights} = this.parsePointsToMapCoordinatesAndHeights()

                const payload = {
                    "uuid": this.touristicObject.uuid,
                    "internal_identifier": this.touristicObject.uuid,
                    "name": defaultLanguage.translation.name,
                    "category": this.getCategory(),
                    "default_language_id": this.touristicObject.default_language.id,
                    "resort_uuid":this.$route.params.uuid,
                    "published": this.touristicObject.published,
                    "weight": this.touristicObject.weight,
                    "timeofyear": this.touristicObject.timeofyear ,
                    "selection": this.touristicObject.selection,
                    "selections": this.touristicObject.selections,
                    "resort_reference": this.touristicObject.resort_reference,
                    "coordinates": this.hasCoordinates ? this.touristicObject.coordinates : null,
                    ...(this.touristicObject.type != null ? {'type_id' : this.touristicObject.type }: null)  ,
                    ...(this.touristicObject.zone != "" ? {'zone_uuid' : this.touristicObject.zone }: null)  ,
                    ...(this.touristicObject.address != "" ? {'address' : this.touristicObject.address }: null)  ,
                    ...(this.touristicObject.start_date != null  ? {'start_date' :this.touristicObject.start_date} : null)  ,
                    ...(this.touristicObject.end_date != null  ? {'end_date' :this.touristicObject.end_date} : null) ,
                    "map_coordinates": map_coordinates,
                    "heights": heights,
                    "subtype_id": this.generateNumberPayloadValue(this.subtypeId),
                    "ride_thrill_id": this.generateNumberPayloadValue(this.rideThrillId),
                    "ride_audience_id": this.generateNumberPayloadValue(this.rideAudienceId),
                    "requirements_ids": this.requirementsSelected.length > 0 ? this.requirementsSelected.map((r) => r.id) : null,
                    "page_id": this.touristicObject.page_id,
                    "min_height": this.generateNumberPayloadValue(this.touristicObject.min_height),
                    "max_height": this.generateNumberPayloadValue(this.touristicObject.max_height),
                    "min_age": this.generateNumberPayloadValue(this.touristicObject.min_age),
                    "tickets": this.generateNumberPayloadValue(this.touristicObject.tickets),
                    "length": this.generateNumberPayloadValue(this.touristicObject.length),
                    "start_height": this.generateNumberPayloadValue(this.touristicObject.start_height),
                    "end_height": this.generateNumberPayloadValue(this.touristicObject.end_height),
                    "time": this.generateNumberPayloadValue(this.touristicObject.time),
                    "total_ascent": this.generateNumberPayloadValue(this.touristicObject.total_ascent),
                    "total_descent": this.generateNumberPayloadValue(this.touristicObject.total_descent),
                    ...( this.getDifficultyIdentifier(this.difficultyId) !== null ? { "facility_difficulty_id": this.getDifficultyIdentifier(this.difficultyId) } : null),
                }

                if (this.referenceSystem === 'imperial') {
                    payload.min_height = this.$options.filters.value_system_converter(payload.min_height, 'metric', 'depth')
                    payload.max_height = this.$options.filters.value_system_converter(payload.max_height, 'metric', 'depth')
                    payload.start_height = this.$options.filters.value_system_converter(payload.start_height, 'metric', 'height')
                    payload.end_height = this.$options.filters.value_system_converter(payload.end_height, 'metric', 'height')
                    payload.total_ascent = this.$options.filters.value_system_converter(payload.total_ascent, 'metric', 'height')
                    payload.total_descent = this.$options.filters.value_system_converter(payload.total_descent, 'metric', 'height')
                    payload.length = this.$options.filters.value_system_converter(payload.length, 'metric', 'distance')
                }

                return payload
            }
        },
        getDifficultyIdentifier(difficulty)
        {
            console.log(difficulty.value, this.category, this.touristicObject.type)
            if (this.touristicObject.type === 183) {
                switch (difficulty.value) {
                    case 'novice':
                        return 0
                    case 'easy':
                        return 1
                    case 'intermediate':
                        return 2
                    case 'advanced':
                        return 3
                    case 'expert':
                        return 4
                    case 'freeride':
                        return 5
                    case 'extreme':
                        return 6
                }
            }

            if (this.touristicObject.type === 186) {
                switch (difficulty.value) {
                    case 'easy':
                        return 7
                    case 'intermediate':
                        return 8
                    case 'advanced':
                        return 9
                    case 'expert':
                        return 10
                }
            }

            return null
        },
        generateNumberPayloadValue(value) {
            return value === '' || value === null ? null : Number(value)
        },
        generateTouristicObjectTranslationPayload(translation){
            return {
                name : translation.name,
                highlight: translation.highlight,
                idioma_id : translation.idioma_id,
                ...(translation.phone != null ? {'phone' : translation.phone} : null) ,
                ...(translation.other_phone != null ? {'other_phone' : translation.other_phone} : null) ,
                ...(translation.email != null ? {'email' : translation.email} : null),
                ...(translation.other_email != null ? {'other_email' : translation.other_email} : null),
                ...(translation.web_link != null ? {'web' : translation.web_link} : null),
                ...(translation.description != null ? {'description' : translation.description} : null),
                ...(translation.reservation_link != null ? {'reservation_link' : translation.reservation_link} : null),
                ...(translation.rates != null ? {'rates' : translation.rates} : null),
                ...(translation.schedule != null ? {'schedule' : translation.schedule} : null),
                ...(translation.services_tags != null ? {'services_tags' : translation.services_tags} : null),
                ...(translation.equipments_tags != null ? {'equipments_tags' : translation.equipments_tags} : null),
                ...(translation.payments_mode_tags != null ? {'payments_mode_tags' : translation.payments_mode_tags} : null),
                ...(translation.comfort_tags != null ? {'comfort_tags' : translation.comfort_tags} : null),
                ...(translation.accessibility_tags != null ? {'accessibility_tags' : translation.accessibility_tags} : null),
            }
        },
        generateNewTouristicObjectTranslationPayload(translation){
            return {
                name : translation.name,
                highlight: translation.highlight,
                idioma_id : translation.idioma_id,
                web:translation.web_link !== undefined ? translation.web_link : '' ,
                phone:translation.phone !== undefined ? translation.phone : '' ,
                other_phone:translation.other_phone !== undefined ? translation.other_phone : '' ,
                email:translation.email !== undefined ? translation.email : '' ,
                other_email:translation.other_email !== undefined ? translation.other_email : '' ,
                description:translation.description !== undefined ? translation.description : '' ,
                reservation_link:translation.reservation_link !== undefined ? translation.reservation_link : '' ,
                ...(translation.rates != null ? {'rates' : translation.rates} : null),
                ...(translation.schedule != null ? {'schedule' : translation.schedule} : null),
                ...(translation.services_tags != null ? {'services_tags' : translation.services_tags} : null),
                ...(translation.equipments_tags != null ? {'equipments_tags' : translation.equipments_tags} : null),
                ...(translation.payments_mode_tags != null ? {'payments_mode_tags' : translation.payments_mode_tags} : null),
                ...(translation.comfort_tags != null ? {'comfort_tags' : translation.comfort_tags} : null),
                ...(translation.accessibility_tags != null ? {'accessibility_tags' : translation.accessibility_tags} : null),
            }
        },
        goBackToList(){
            if (this.pageType == 'events')this.$router.push({name:'ToEvents'})
            if (this.pageType == 'services')this.$router.push({name:'ToServices'})
            if (this.pageType == 'facilities')this.$router.push({name:'ToFacilities'})
        },
        validateService(){

            const { start_date,end_date,timeofyear, weight,default_language,type } = this.touristicObject;
            const errors = [];

            if (default_language == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.defaultLanguage'));
            }
            if (timeofyear == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.timeofyear'));
            }
            if (type == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.type'));
            }

            if (weight === 0 || weight === '') {
                errors.push( this.$t('resorts.touristic-objects.form.errors.weight'));
            }
            if (start_date == "" ) {
                this.touristicObject.start_date = null
            }
            if (end_date == "" ) {
                this.touristicObject.end_date = null
            }


            return errors;
        },
        validateEvent(touristicObject){
            const {  start_date, end_date, weight,default_language } = touristicObject;

            const errors = [];

            if (default_language == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.defaultLanguage'));
            }

            if (start_date == "") {
                errors.push(this.$t('resorts.touristic-objects.form.errors.start_date'));
            }

            if (weight === 0 || weight === '') {
                errors.push(this.$t('resorts.touristic-objects.form.errors.weight'));
            }
            if (end_date == "" ) {
                errors.push(this.$t('resorts.touristic-objects.form.errors.end_date'));

            }

            return errors;
        },
        handleInvalidCoords(eventInvalidCoordinates) {
            this.$vs.notify({
                    type:'confirm',
                    title: "Error",
                    text: `${eventInvalidCoordinates}`,
                    color: "danger",
                    position: "top-right"
                });
        },
        handleSelectDifficultyChange(option) {
			this.difficultyId = option;
		},
    },
}
</script>
<style >
.v-selector .vs__deselect {
    display: none;
}

</style>
